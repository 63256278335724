import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { SNACKBAR_COLORS } from '../../Snackbar/constant';

export const StyledTextFields = styled(TextField)<{
  $isDirty?: boolean;
}>`
  // field set
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => (props?.$isDirty ? SNACKBAR_COLORS.warning : props.theme.colors.borderColor)};
  }

  // input
  & .MuiOutlinedInput-input {
    padding: 16px;
    color: ${(props) => props.theme.colors.textColor};

    &::placeholder {
      color: ${(props) => props.theme.colors.textColor};
      -webkit-text-fill-color: ${(props) => props.theme.colors.textColor};
    }
  }

  // TextField container
  & .MuiOutlinedInput-root {
    border-radius: 4px;

    & > * {
      transition: all 0.3s ease-out;
    }

    & .MuiAutocomplete-endAdornment {
      svg {
        path {
          fill: ${(props) => props.theme.colors.grey1};
        }
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    padding: 0;
    margin: 4px 0 0 0;
    font-size: 14px;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  // small input
  & .MuiInputBase-sizeSmall {
    height: 40px;

    & .MuiOutlinedInput-input {
      padding: 10px 12px;
    }
  }

  // focused state
  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  // disabled state
  & .Mui-disabled {
    cursor: not-allowed;
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.borderColor};
      opacity: 0.5;
    }

    & .MuiOutlinedInput-input {
      color: ${(props) => props.theme.colors.textColor};
      -webkit-text-fill-color: ${(props) => props.theme.colors.textColor};
      &::placeholder {
        color: ${(props) => props.theme.colors.textColor};
        -webkit-text-fill-color: ${(props) => props.theme.colors.textColor};
      }
    }
  }

  // error state
  & .Mui-error {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.red};
    }
  }
`;

export const StyledTextInputTitle = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding-top: 10px;
`;

export const StyledTextWrapped = styled.div`
  display: flex;
  flex-direction: column;
`;
