import * as React from 'react';
import Zoom from '@mui/material/Zoom';
import { useTheme } from 'styled-components';
import { InfoIcon } from 'src/assets/icons';
import { StyledWrapper, StyledTooltip } from './InfoTooltip.styled';

interface InfoTooltipProps {
  title: React.ReactNode;
}

export const InfoTooltip: React.FunctionComponent<InfoTooltipProps> = ({ title }) => {
  const theme = useTheme();
  return (
    <StyledTooltip title={title} TransitionComponent={Zoom} $scTheme={theme} placement="top" arrow>
      <StyledWrapper>
        <InfoIcon />
      </StyledWrapper>
    </StyledTooltip>
  );
};
