import * as React from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { COLORS } from 'src/constants/theme';
import { StyledTextFields, StyledTextInputTitle, StyledTextWrapped } from '../common';
import { P16 } from '../../Typography';
import { StyledInputRequiredStar } from './TextInput.styled';
import { SNACKBAR_COLORS } from '../../Snackbar/constant';
import { InfoTooltip } from '../../InfoTooltip';

export interface TextInputProps {
  /** Value of the input - should be read from state using two way binding */
  value?: string;
  /** Placeholder of the input */
  placeholder: string;
  /** Name of the input */
  name: string;
  /** Label of the input */
  label?: string;
  /** Size of the input - set to true if you want an input with smaller height */
  small?: boolean;
  /** Set to true to show red asterisk on the label */
  required?: boolean;
  /** Helper text below the Input - use with error state */
  helperText?: string;
  /** Set to true to toggle on Error state */
  error?: boolean;
  /** Set to true to toggle on Disabled state */
  disabled?: boolean;
  /** onChange handler of the input */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Icon to place on the right of the input - example; visibility icon on password input */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  endAdornment?: React.ReactNode;
  /** Type of the input element. It should be a valid HTML5 input type. */
  type?: string;
  defaultValue?: string;
  isDirty?: boolean;
  greyLabel?: boolean;
  tooltip?: string;
}

export const TextInput: React.FunctionComponent<TextInputProps> = ({
  small,
  label,
  required,
  helperText,
  error,
  value,
  placeholder,
  disabled,
  onChange,
  name,
  endAdornment,
  type,
  onBlur,
  defaultValue,
  isDirty,
  greyLabel,
  tooltip,
}) => {
  const { t } = useTranslation();
  return (
    <StyledTextWrapped>
      <StyledTextInputTitle>
        {!isEmpty(label) && (
          <P16
            as="label"
            htmlFor={name}
            $margin="0 0 6px 0"
            $display="block"
            $fontWeight={500}
            {...(disabled
              ? {
                  $opacity: 0.5,
                  $cursor: 'not-allowed',
                }
              : {})}
            {...(greyLabel
              ? {
                  $color: COLORS.grey1,
                }
              : {})}
            {...(isDirty
              ? {
                  $color: SNACKBAR_COLORS.warning,
                }
              : {})}
          >
            {t(label as string)}&nbsp;{required && <StyledInputRequiredStar>*</StyledInputRequiredStar>}
          </P16>
        )}
        {tooltip ? <InfoTooltip title={tooltip} /> : <br />}
      </StyledTextInputTitle>

      <StyledTextFields
        id={name}
        name={name}
        value={value}
        placeholder={t(placeholder)}
        helperText={(!isEmpty(helperText) && t(helperText as string)) || ''}
        error={error}
        onChange={onChange}
        fullWidth
        disabled={disabled}
        $isDirty={isDirty}
        type={type}
        onBlur={onBlur}
        {...(small && { size: 'small' })}
        inputProps={{
          'aria-label': label,
          'data-cy': 'text-input',
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment,
        }}
        defaultValue={defaultValue}
      />
    </StyledTextWrapped>
  );
};

TextInput.defaultProps = {
  small: false,
  required: false,
  helperText: '',
  error: false,
  disabled: false,
  onChange: undefined,
  label: '',
  value: undefined,
  endAdornment: undefined,
  type: 'text',
  onBlur: undefined,
  defaultValue: undefined,
  isDirty: false,
  greyLabel: false,
  tooltip: '',
};
