import { generateVRID } from 'src/utils';
import isEmpty from 'lodash/isEmpty';
import type { BoundingBoxConfig, MapBoundingBoxConfig } from 'src/types';
import { BOX_COLORS } from '../atoms/ZoneCanvas/colors';

export interface GetRelativeShapeParams extends Omit<BoundingBoxConfig, 'fill' | 'id'> {
  stageWidth: number;
  stageHeight: number;
  isPrevious?: boolean;
  isPreviousVersion?: boolean;
  id?: string;
  isRollbackPreview?: boolean;
}

export interface GetMapRelativeShapeParams extends Omit<Partial<MapBoundingBoxConfig>, 'fill' | 'id'> {
  stageWidth: number;
  stageHeight: number;
  isPrevious?: boolean;
  isPreviousVersion?: boolean;
  id?: string;
  isRollbackPreview?: boolean;
  fill?: string;
}

/**
 * getRelativePoints
 * make points relative to the canvas size
 */

const getRelativePoints = ({
  points,
  stageWidth,
  stageHeight,
}: Pick<GetRelativeShapeParams, 'points' | 'stageWidth' | 'stageHeight'>) => {
  return points.map((point) => {
    if (Array.isArray(point)) {
      const [x, y] = point;

      const newX = x * (stageWidth / 1282);
      const newY = y * (stageHeight / 722);

      return [newX, newY];
    }

    return point;
  });
};

/**
 * getRelativeShapeProps
 * NOTE: for backwards compatibility and responsive canvas, we need to make sure that the shape props are relative to the canvas size
 *
 * 1282 x 722 is the fixed canvas size of the previous Zone Editor and it's the sizing unit that backend expects. so here, we translate the shape props to the new editor respective to the canvas size.
 *
 * TODO: transform back to 1282 x 722 when sending to backend
 */
export const getRelativeShapeProps = (arg: GetRelativeShapeParams) => {
  const relativeX = arg.x * (arg.stageWidth / 1282);
  const relativeY = arg.y * (arg.stageHeight / 722);
  const relativeWidth = arg.width * (arg.stageWidth / 1282);
  const relativeHeight = arg.height * (arg.stageHeight / 722);

  return {
    ...arg,
    x: relativeX,
    y: relativeY,
    width: relativeWidth,
    height: relativeHeight,
    /**
     * give the previous boxes a different color
     */
    fill:
      // eslint-disable-next-line no-nested-ternary
      arg?.isRollbackPreview && arg.isPrevious
        ? BOX_COLORS.rollback
        : arg.isPrevious
          ? BOX_COLORS.previous
          : BOX_COLORS.new,
    id: arg?.id || generateVRID(),
    points: isEmpty(arg.points)
      ? arg.points
      : getRelativePoints({
          points: arg.points,
          stageWidth: arg.stageWidth,
          stageHeight: arg.stageHeight,
        }).flat(),
    isDrawing: arg?.isPrevious ? false : arg?.isDrawing,
  };
};

export const getMapRelativeShapeProps = (arg: GetMapRelativeShapeParams): MapBoundingBoxConfig => {
  return {
    ...arg,
    fill: arg?.fill || BOX_COLORS.new,
    id: arg.name || arg.id || generateVRID(),
    name: arg.name || arg.id || generateVRID(),
    points: isEmpty(arg.points)
      ? arg.points || []
      : getRelativePoints({
          points: arg?.points as Array<number | Array<number>>,
          stageWidth: arg.stageWidth,
          stageHeight: arg.stageHeight,
        }).flat(),
    isDrawing: Boolean(arg.isDrawing),
  };
};
