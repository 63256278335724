import { z } from 'zod';
import { TASK_SCHEMA } from '../../list-tasks/listTasksPromise';

export const RESINGESTOR_BASE_SCHEMA = TASK_SCHEMA.pick({
  action_type: true,
}).extend({
  index1: z.string().or(z.number()),
  index2: z.string().or(z.number()),
  earliest1: z.string(),
  latest1: z.string(),
  latest2: z.string(),
  source1: z.string().or(z.number()),
  time_offset: z.string(),
  modify_amount: z.number(),
  sourcetype1: z.string(),
  email_address: z.string().email(),
  blur: z.boolean(),
  only_positional: z.boolean(),
});

export type ReingestorBaseSchema = z.infer<typeof RESINGESTOR_BASE_SCHEMA>;
